import * as React from "react";
import styled from "styled-components";
import media from "css-in-js-media";

import { Container } from "./Row";
import { Link } from "./Link";
import { IsoAccreditation } from "./IsoAccreditation";

import FullLogo from "../images/icons/logo--full--orange.svg";
import IconLocation from "../images/icons/icon__location.svg";
import IconPhone from "../images/icons/icon__phone.svg";
import IconMail from "../images/icons/icon__mail.svg";
import IconLinkedin from "../images/icons/icon__linkedin.svg";
import { ILinkSet } from "../utils/transformMenuData";

const SiteFooterBG = styled.footer`
  background: var(--colour-grey-1);
`;

const SiteFooter = styled(Container)`
  font-size: var(--body-font-size-small);
  color: var(--colour-white);
  padding: 30px;
  display: grid;

  grid-template:
    "contact" auto
    "menu" auto
    "logos" auto
    "tsandcs" auto /
    auto;

  grid-gap: var(--layout-container-grid-gap);

  ${media(">tablet")} {
    grid-template:
      "contact menu logos" auto
      "tsandcs menu logos" auto /
      2fr 1fr 1fr;
    padding: 60px 30px;
    grid-gap: var(--layout-container-grid-gap);
  }
`;

const BlockLink = styled(Link)`
  display: block;
  color: var(--colour-white);
`;

const SiteFooterMenu = styled.nav`
  grid-area: menu;

  ul {
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
  }

  li {
    margin-bottom: 15px;
  }
`;

const SiteFooterContact = styled.div`
  grid-area: contact;
  font-style: normal;
  width: auto;

  p {
    margin: 4px 0;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
  }

  li {
    display: flex;
    margin-bottom: 15px;
  }

  .no-link {
    text-decoration: none;
  }

  .footer__icon {
    position: relative;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-right: 15px;
  }
`;

const SiteFooterTsAndCs = styled.div`
  grid-area: tsandcs;

  p {
    font-size: 12px;
    margin: 0;
  }
`;

const SiteFooterLogos = styled.div`
  grid-area: logos;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: min-content;
  height: 100%;
  gap: var(--layout-container-grid-gap);
  align-items: start;
  ${media(">tablet", "<desktop")} {
    align-items: end;
  }
`;

export function Footer({ links }: { links: ILinkSet[] }): JSX.Element {
  return (
    <SiteFooterBG>
      <SiteFooter className={`site-footer`}>
        <SiteFooterMenu>
          <ul>
            {links.map((link) => (
              <li key={link.id}>
                <BlockLink to={link.destination}>{link.title}</BlockLink>
              </li>
            ))}
          </ul>
        </SiteFooterMenu>
        <SiteFooterContact>
          <ul>
            <li>
              <IconLocation className={`footer__icon`} />
              <address>
                <BlockLink to="https://www.google.com/maps/search/Union+Mills,+9+Dewsbury+Road,+Leeds,+LS11+5DD/@53.7887855,-1.546023,17z/data=!3m1!4b1">
                  Union Mills, 9 Dewsbury Road, Leeds, LS11 5DD
                </BlockLink>
              </address>
            </li>
            <li>
              <IconPhone className={`footer__icon`} />
              <BlockLink to="tel:01132010600" className={`no-link`}>
                0113 201 0600
              </BlockLink>
            </li>
            <li>
              <IconMail className={`footer__icon`} />
              <BlockLink to="mailto:enquiries@answerdigital.com">enquiries@answerdigital.com</BlockLink>
            </li>
            <li>
              <IconLinkedin className={`footer__icon`} />
              <BlockLink to="https://www.linkedin.com/company/answerdigital/">LinkedIn</BlockLink>
            </li>
          </ul>
        </SiteFooterContact>
        <SiteFooterTsAndCs>
          <p>
            Answer Digital Limited. Registered in England and Wales. Company number: 3655429.
            Registered office: Union Mills, 9 Dewsbury Road, Leeds. LS11 5DD. "Answer Digital" is a
            registered trademark of Answer Digital Limited.
          </p>
        </SiteFooterTsAndCs>
        <SiteFooterLogos>
          <FullLogo />
          <IsoAccreditation />
        </SiteFooterLogos>
      </SiteFooter>
    </SiteFooterBG>
  );
}
